import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'


import Loader from "../components/loader.js";

const Head = loadable(() => import("../components/head.js"));
const InnerLayout = loadable(() => import('../components/Innerlayout.js'));
const EngNftcollectionPage = loadable(() => import("../components/NftcollectionPage/english.js"));
const ArabicNftcollectionPage = loadable(() => import("../components/NftcollectionPage/arabic.js"));
const ChineseNftcollectionPage = loadable(() => import("../components/NftcollectionPage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section.webp",
            "name": "Leading NFT Development Services Provider",
            "description": "Softtik can help you enter into this billion-dollar industry with its top-notch NFT development services through a highly skilled and knowledgeable team.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "143"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What Does an NFT Developer Do?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>An <b>NFT developer</b> is a knowledgeable individual who understands the basics and can develop NFTs utilizing his coding skills.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How do I hire an NFT Developer?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>You can reach out to <b>Softtik Technologies</b>, by using the numbers given below, the developers’ team as they can assist you in developing your NFT project.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How much does it cost to develop an NFT?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p> There’s no exact value for the development of NFT projects as it varies from project to project with a set of requirements.</p>"
                }
            }
            ]
        }
    ]
}

export class NftcollectionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };


    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/nft-development-service/"
                        title="NFT Development Services Provider - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section.webp"
                        description="Softtik can help you enter into this billion-dollar industry with its top-notch NFT development services through a highly skilled and knowledgeable team."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicNftcollectionPage />
                                    : lang == "China"
                                        ? <ChineseNftcollectionPage />
                                        : <EngNftcollectionPage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default NftcollectionPage;